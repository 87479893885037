import React from "react"

export default function SummarySection() {
  return (
    <section>
      <hr className="divider" />
      <div className="container summary-data is-flex is-flex-direction-column px-3">
        <div className="columns is-vcentered proprietary-data is-flex-gap-5">
          <div className="column">
            <h1 className="title is-size-1-desktop text-dark-blue">
              Proprietary Data
            </h1>
            <p className="is-size-4-desktop is-size-5-mobile">
              Our unique data sets can’t be found anywhere else - inPowered AI’s
              optimization algorithms are trained on 10+ years of continually
              updated high intent data. Our algos are proven to drive the
              specific outcomes that matter to you without first party data.
            </p>
          </div>
          <div className="column is-justify-content-center is-flex">
            <div className="image-placeholder">
              <img
                src={require("../../images/pmp/data.svg")}
                alt="Data Illustration"
                style={{ height: 150, display: "block" }}
              />
            </div>
          </div>
        </div>

        <div className="columns is-vcentered industry-data is-flex-gap-5">
          <div className="column is-justify-content-center is-flex">
            <div className="image-placeholder is-hidden-mobile">
              <img
                src={require("../../images/pmp/ai-decisioning.svg")}
                alt="AI Decisioning"
                style={{ height: 300, display: "block" }}
              />
            </div>
          </div>
          <div className="column">
            <h1 className="title is-size-1-desktop text-dark-blue">
              Industry-First Data Partnerships
            </h1>
            <p className="is-size-4-desktop is-size-5-mobile">
              It’s optional to onboard your first and third party data for a
              more customized output. inPowered AI’s data partnerships with
              Index Exchange, Innovid, Edo, & Cint ensure your optimization
              algorithms are trained to drive any KPI. 
            </p>
            <div className="image-placeholder is-hidden-desktop is-justify-content-center is-flex py-5">
              <img
                src={require("../../images/pmp/ai-decisioning-mobile.svg")}
                alt="AI Decisioning"
                style={{ height: 300, display: "block" }}
              />
            </div>
          </div>
        </div>

        <div className="columns is-vcentered turnkey-activation is-flex-gap-5">
          <div className="column is-justify-content-center is-flex is-flex-direction-column">
            <h1 className="title is-size-1-desktop text-dark-blue">
              Turnkey Activation
            </h1>
            <p className="is-size-4-desktop is-size-5-mobile">
              Turnkey AI Optimization is as simple as generating a Deal ID. No
              IOs or minimum spend necessary.
            </p>
          </div>
          <div className="column is-justify-content-center is-flex">
            <div className="image-placeholder">
              <img
                src={require("../../images/pmp/deal-id.svg")}
                alt="Deal ID"
                style={{ height: 250, display: "block" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
